import Vue from "vue";
import VueRouter from "vue-router";
import OffcialHome from "../views/OffcialHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "offcialHome",
    component: OffcialHome
  },
  {
    path: "/parent",
    component: () => import("../views/func/index.vue"),
    children: [
      {
        path: "create",
        component: () => import("../views/func/components/CreateText.vue"),
      },
    ],
  },
  {
    path: "/docs/:docName",
    component: () => import("../views/docs"),
  },
  {
    path: '/userAgreement',  //用户协议
    component: () => import('../views/privacy/userAgreement.vue'),
  },
  {
    path: '/privacy', //隐私协议
    component: () => import('../views/privacy/privacyPolicy.vue'),
  },
  {
    path: '/childrenPrivacy',  //儿童隐私协议
    component: () => import('../views/privacy/childrenPrivacyPolicy.vue'),
  },
  {
    path: '/agreement/:id', //协议新版(包含隐私协议 用户协议 隐私政策协议)
    component: () => import('../views/privacy2/privacyPolicyNew.vue'),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/leqiai_tingxie",
  routes
});

export default router;
