import Vue from "vue";
import {
  Button,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Dialog,
  Message
} from "element-ui";

Vue.prototype.$message=Message

Vue.use(Button)
  .use(Input)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Form)
  .use(FormItem)
  .use(Dialog);
