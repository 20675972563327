<template>
  <div class="qrcode" id="qrcode"></div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      qrcode: {}
    }
  },
  props: {
    codeKey: {
      type: String,
      required: true
    }
  },
  watch: {
    codeKey(newValue) {
      this.createQrCode(newValue);
      // console.log(qrcode);
      // console.log(this.qrcode);
      this.qrcode.makeCode(newValue); //替换二维码图片
    }
  },
  methods: {
    createQrCode(key) {
      let qrcodeChildren = document.getElementById("qrcode");
      // console.log(qrcodeChildren.children.length);
      if (qrcodeChildren.children.length === 0) {
        //防止创建多个二维码
        this.qrcode = new QRCode(document.getElementById("qrcode"), {
          text: key,
          width: 180,
          height: 180,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.qrcode {
  margin-top: 30px;
}
</style>
