<template>
  <div class="all">
    <!-- logo navigation -->
    <div class="navigation">
      <div class="logo_f">
        <img src="../assets/image/logo_b.png" class="logo" />
        <span class="logo_t"> &nbsp; 乐其爱·听写</span>
      </div>
      <div>
        <div class="login_name" style="display: inline-block" @click="login">
          <span>登录</span>
        </div>
        <div style="display: inline" class="navigation-title">
          <span class="navbar-colored" @click="handleScroll('#function')"
            >产品介绍</span
          >
          <span @click="handleScroll('#special')">产品特色</span>
          <!-- <span @click="handleScroll('#download')">APP下载</span> -->
        </div>
      </div>
    </div>

    <!-- 登录弹窗 -->
    <Login :visible="visible" @closeLogin="visible = 'none'" />
    <!-- 假的导航栏 -->
    <div class="fake-navi"></div>
    <!-- 主内容 -->
    <div class="header" id="download">
      <img src="../assets/image/home_top.png" alt="" class="header-image" />
      <div class="header-content">
        <div class="info">扫码下载或在各大应用商店搜索「乐其爱听写」</div>
        <div class="qrcode-wrapper">
          <div v-for="item in qrcodes" :key="item.qrname" class="qrcode">
            <div class="qrcode-img-wrapper">
              <img :src="item.qrcode" alt="" class="qrcode-img" />
            </div>
            <div class="icon-wrapper">
              <!-- <img :src="item.qricon" alt="" class="icon-img" /> -->
              <span class="icon-name">{{ item.qrname }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 过渡 //产品介绍-->
    <!-- <div class="connect" id="function">
    </div> -->

    <!-- 详细功能 -->
    <div
      class="detail-content-wrapper detail-content-wrapper-bgg"
      :class="{ test: true, testenter: detailShow1 }"
      id="function"
    >
      <!-- <template > -->
      <div class="photo">
        <div class="detail-title">
          系统固定内容<br />
          <img
            src="../assets/image/detail_title_decoration.png"
            style="
              height: 15px;
              position: absolute;
              left: 17%;
              bottom: 51%;
              z-index: -5;
            "
            alt=""
          />
        </div>
        <div class="detail-content">一键展开词语，随时随地听写</div>
      </div>
      <!-- </template> -->

      <img src="../assets/image/home_intro.png" alt="" class="detail-img" />
    </div>
    <div
      class="detail-content-wrapper"
      :class="{ test: true, testenter: detailShow2 }"
    >
      <div class="bg-img">
        <img
          src="../assets/image/home_create_intro_bg.png"
          width="100%"
          height="100%"
        />
      </div>

      <img
        src="../assets/image/home_create_intro.png"
        alt=""
        class="detail-img"
        style="background-color: transparent; margin-left: 10%"
      />
      <div class="photo">
        <div class="detail-title">
          创建内容，听写更自由
          <img
            src="../assets/image/detail_title_decoration.png"
            style="
              height: 15px;
              position: absolute;
              right: 17%;
              bottom: 51%;
              z-index: -5;
            "
            alt=""
          />
        </div>
        <div class="detail-content">一次录入，多次使用</div>
      </div>
    </div>

    <div
      class="detail-content-wrapper detail-content-wrapper-bgg"
      :class="{ test: true, testenter: detailShow3 }"
    >
      <!-- <template > -->
      <div class="photo">
        <div class="detail-title">
          文件夹管理
          <img
            src="../assets/image/detail_title_decoration.png"
            style="
              height: 15px;
              position: absolute;
              left: 17%;
              bottom: 51%;
              z-index: -5;
            "
            alt=""
          />
        </div>
        <div class="detail-content">创建文件夹，将学习分类管理</div>
      </div>
      <!-- </template> -->

      <img src="../assets/image/home_dir_intro.png" alt="" class="detail-img" />
    </div>

    <div
      class="detail-content-wrapper"
      :class="{ test: true, testenter: detailShow4 }"
      style="margin-bottom: 200px"
    >
      <div class="history-bg-img">
        <img
          src="../assets/image/home_history_intro_bg.png"
          width="100%"
          height="100%"
        />
      </div>
      <img
        src="../assets/image/home_histroy_intro.png"
        alt=""
        class="detail-img"
      />
      <div class="photo">
        <div class="detail-title">
          我的听写内容池，听写记录全呈现
          <img
            src="../assets/image/detail_title_decoration.png"
            style="
              height: 15px;
              position: absolute;
              right: 22%;
              bottom: 51%;
              z-index: -5;
            "
            alt=""
          />
        </div>
        <div class="detail-content">清晰管理，成长记录</div>
      </div>
    </div>

    <!-- 过渡 -->
    <div class="connect" id="special">
      <img
        src="../assets/image/route-icon.png"
        alt=""
        class="route-icon"
        style="padding-top: 0"
      />
      <div class="conetent-title-bg">
        <div class="content-title">我的特色是？</div>
        <div class="content-title-line">
          <img
            src="../assets/image/detail_title_decoration.png"
            alt=""
            style="width: 160px"
          />
        </div>
      </div>
    </div>

    <!-- 特色 -->
    <div class="special-wrapper">
      <div v-for="item in specialIcons" :key="item.title" class="item-wrapper">
        <img
          src="../assets/image/square-wrapper.png"
          alt=""
          class="special-bg"
        />
        <div class="special-content">
          <img :src="item.icon" alt="" />
          <div class="item-title">{{ item.title }}</div>
          <div class="item-content">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="footer-wrapper">
      <div>
        <router-link class="right-limit" to="/agreement/14" target="_blank"
          >隐私政策</router-link
        >
        <router-link class="right-limit" to="/agreement/13" target="_blank"
          >儿童隐私政策</router-link
        >
        <router-link class="right-limit" to="/agreement/15" target="_blank"
          >用户协议</router-link
        >
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >沪ICP备19013413号-3</a
        >
      </div>
      <div style="padding-top: 1.2%">
        <span class="right-limit">Copyright©2023 上海乐其爱科技有限公司</span>

        <span>联系电话：<a href="tel:17521697351">17521697351</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./login/index.vue";
export default {
  components: {
    Login,
  },
  data() {
    return {
      qrcodes: [
        // {
        //   qrcode: require("../assets/image/downloadQRcode.png"),
        //   qricon: require("../assets/image/pingguo.png"),
        //   qrname: "ios下载"
        // },
        {
          qrcode: require("../assets/image/downloadQRcode.png"),
          qricon: require("../assets/image/anzhuo.png"),
          qrname: "安卓下载",
        },
        // {
        //   qrcode: require("../assets/image/gongzhonghaoQRcode.jpg"),
        //   qricon: require("../assets/image/gongzhonghao.png"),
        //   qrname: "公众号"
        // }
      ],
      specialIcons: [
        {
          icon: require("../assets/image/special-icon1.png"),
          title: "数据库",
          content: "专业教师倾力打造，字词文章分类覆盖",
        },
        {
          icon: require("../assets/image/special-icon2.png"),
          title: "听写记录",
          content: "听写数据留档，记录成长时刻",
        },
        {
          icon: require("../assets/image/special-icon3.png"),
          title: "专业语音",
          content: "定制语音进行播报，告别传统“机械音”",
        },
      ],
      detailShow1: true,
      detailShow2: false,
      detailShow3: false,
      detailShow4: false,
      visible: "none",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollTo);

    // const html = document.getElementsByTagName("html")[0];
    // console.log(parseFloat(html.style.fontSize) / document.body.style.zoom);
    // html.style.fontSize =
    //   parseFloat(html.style.fontSize) / document.body.style.zoom + "px";
  },
  methods: {
    handleScroll(el) {
      this.$el.querySelector(el).scrollIntoView({ behavior: "smooth" });
    },
    handleScrollTo() {
      this.currentScroll = window.pageYOffset; // 表示当前滚动的位置
      // console.log(this.currentScroll);
      // console.log(this.currentScroll == this.$refs.testref.offsetTop);
      const navbar = document.querySelector(".navigation-title");
      const productDesc = document.querySelector(".footer-wrapper");
      const productDescrect = productDesc.getBoundingClientRect();
      if (this.currentScroll < productDescrect.bottom) {
        navbar.firstChild.classList.add("navbar-colored");
        navbar.lastChild.classList.remove("navbar-colored");
      } else {
        navbar.firstChild.classList.remove("navbar-colored");
        navbar.lastChild.classList.add("navbar-colored");
      }
      if (this.currentScroll >= 150) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.detailShow1 = true;
      }

      if (this.currentScroll >= 400) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.detailShow2 = true;
      }

      if (this.currentScroll >= 1000) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.detailShow3 = true;
      }

      if (this.currentScroll >= 1200) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.detailShow4 = true;
      }

      // console.log(this.currSentScroll);

      // if (this.currentScroll >= 1800) {
      //   //当前滚动位置到达testref的时候，显示div（100作为调整用）
      //   this.detailShow3 = true;
      // } else {
      //   this.detailShow3 = false;
      // }
    },
    login() {
      console.log("点击了登录");
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/parent/create",
        });
        return;
      }
      this.visible = "block";
      // document.body.style.overflow = "hidden"; // 登录弹窗弹出时禁止页面滚动
    },
  },
};
</script>
<style lang="less" src="./OffcialHome.less"></style>
