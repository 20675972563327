import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element";

// import { detectZoom } from '../utils';
// 处理笔记本系统默认系统比例为150%带来的布局影响
// const m = detectZoom();
// document.body.style.zoom = 100 / Number(m);

// rem
import "lib-flexible";

import "./plugins/element.js";
// if (width / dpr > width) {
//   width = width * dpr;
// }
// var rem = width / 24;
import md5 from "js-md5";
Vue.config.productionTip = false;
Vue.prototype.md5 = md5
console.log(process.env.NODE_ENV);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
